@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
/* poppins font ^ */

a {
  text-decoration: none !important;
}

.hero {
  height: 100vh;
  z-index: 1;
  background-image: url('./img/hero.png');
  background-size: cover;
  box-shadow: inset 0 0 2000px rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.hackathon-date {
  margin-top: 0px;
  margin-bottom: 0px;
  width: clamp(80%, 90vw, 90%);
}

.hackathon-date h3 {
  font-size: clamp(1.4rem, 2vw, 1.7rem);
  color: #FFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  white-space: normal;
  word-break: break-word;
}

.hackathon-date h3 span {
  white-space: nowrap;
}

.App-logo {
  height: clamp(8rem, 15vw, 15rem);
  margin-bottom: clamp(3rem, 10vw, 6rem);
  pointer-events: none;
}


.text-box {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  border:solid white 1px;
  margin-bottom:clamp(1.5rem, 4vw, 4rem);
}

.text-box:hover {
  transform: scale(1.09);
  transition: 0.25s ease-in-out;
  cursor: pointer;
}

.text-box h4 {
  font-size: 1.3rem;
  color: #FFF;
  margin: 0;
  font-family: inherit;
}

.socials {
  padding:0;
  margin:0;
  margin-top:1rem;
}

.socials h4 {
  color:white;
  font-size: clamp(0.8rem, 1.5vh, 1.2rem);
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0.3rem;
}
 

.logo-box{
  display:flex;
  flex-direction:row;
  gap:0.35rem;
  align-items:center;
  justify-content:center;
}
.SocialLogoIMG{
  max-width:80px;
  display:block;
  margin:0;
}

@media screen and (max-width: 1000px) {
  .button h4 {
    font-size: 1.1rem;
  }
  .hackathon-date h3{
    font-size: 2rem;
  }
}

@media screen and (max-width: 770px) {
  .button h4 {
    font-size: 1rem;
  }
  .hackathon-date h3{
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 600px) {
  .button h4 {
    font-size: 0.9rem;
  }
  .hackathon-date h3{
    font-size: 1.4rem;
  }

}

@media screen and (max-width: 400px) {
  .button h4 {
    font-size: 0.85rem;
  }
  .hackathon-date h3{
    font-size: 1.2rem;
  }

}
